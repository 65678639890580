var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"deposit-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("入金一覧")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"placeholder":"取引先ID","items":_vm.customerIds,"outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"入金日From","dense":"","hide-details":"","outlined":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":"","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"入金日To","dense":"","outlined":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":"","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","to":{ name: 'deposit-create' }}},[_c('span',[_vm._v("NEW")])]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.downloadCSV}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{ref:"contracts",staticClass:"class-on-data-table elevation-1",attrs:{"headers":_vm.headers,"items":_vm.depositList,"search":_vm.searchQuery},scopedSlots:_vm._u([{key:"item.cust_abbr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:('text-decoration: underline; color:#666666')},'a',attrs,false),on),[_vm._v(_vm._s(item.cust_abbr))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cust_id))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name_eng))]),_c('br')])]}},{key:"item.depo_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.pushDepositReconciliation(item)}}},[_vm._v(_vm._s(item.depo_id))])]}},{key:"item.depo_amount",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(item.depo_amount ? item.depo_amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"py-2 pl-1"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)])]}},{key:"body.append",fn:function(){return [(_vm.product_id)?_c('tr',[_c('td',{staticClass:"font-weight-bold",staticStyle:{"background":"#fafafa"},attrs:{"colspan":5}},[_c('label',[_vm._v(_vm._s(_vm.product_id)+"の合計")])]),_c('td',{staticClass:"font-weight-bold",staticStyle:{"text-align":"right","background":"#fafafa"}},[_c('label',[_vm._v(_vm._s(_vm.sumWgt))])]),_c('td',{staticStyle:{"background":"#fafafa"},attrs:{"colspan":3}})]):_vm._e()]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }