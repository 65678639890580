<template>
  <div id="deposit-list">
    <v-card>
      <v-card-title>入金一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先ID"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="入金日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="入金日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'deposit-create' }"
          >
            <span>NEW</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="contracts"
        class="class-on-data-table elevation-1"
        :headers="headers"
        :items="depositList"
        :search="searchQuery"
      >
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.depo_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushDepositReconciliation(item)"
          >{{ item.depo_id }}</a>
        </template>
        <template v-slot:[`item.depo_amount`]="{ item }">
          <label>{{
            item.depo_amount ? item.depo_amount.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : '0'
          }}</label>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
          </div>
        </template>
        <template v-slot:[`body.append`]>
          <tr v-if="product_id">
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>{{ product_id }}の合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumWgt }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  mdiPencil,
  mdiExportVariant,
  mdiCalendar,
} from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'

/* eslint-disable */
export default {
  components: {

  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiExportVariant,
      mdiCalendar,
    },
    dateEndMenu: '',
    dateStartMenu: '',
    searchQuery: '',
    customer_id: '',
    startDate: '',
    endDate: '',
    isExpand: false,
    OperationDisabled: true,
    headers: [
      {
        text: 'ID.',
        value: 'depo_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '12%',
        fixed: true,
      },
      {
        text: '入金日',
        value: 'depo_date',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '通貨',
        value: 'ccy_code',
        align: 'center',
        width: '10%',
        sortable: true,
        fixed: true,
      },
      {
        text: '入金金額',
        value: 'depo_amount',
        align: 'right',
        width: '10%',
        sortable: true,
        fixed: true,
      },
      {
        text: '備考',
        value: 'depo_note',
        align: 'left',
        width: '20%',
        sortable: true,
        fixed: true,
      },
      // {
      //   text: '入金口座',
      //   value: 'depo_account_id',
      //   align: 'right',
      //   width: '20%',
      //   sortable: true,
      //   fixed: true,
      // },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '8%',
        fixed: true,
      },
    ],
  }),

  computed: {
    ...mapState('customerStore', ['customerIds']),
    ...mapState('depositStore', ['depositList']),
  },
  watch: {
    selectedReserveds(val) {
      if (val && val.length > 0) {
        this.OperationDisabled = false
      } else {
        this.OperationDisabled = true
      }
    },

    customer_id(newValue) {
      this.changeRouteQuery('cust_id', newValue)
      this.loadData()
    },

    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },

    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)

        this.loadData()

    },
  },
  created() {
    console.log("created")
    this.loadQuery()
    this.changeRouteQuery()
    // this.loadContractIds()
    // this.loadProductIds()
    // this.loadCustomerIds()
     this.loadData()
  },

  methods: {
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('depositStore', ['loadDeposits']),
    ...mapMutations('app', ['setOverlayStatus']),

    previewItem(item){
      console.log('view', item)
      this.$router.push({
        name: 'quotation-preivew',
        params: {
          id: item.quot_id,
        },
      })
    },

    pushDepositReconciliation(item) {
      this.$router.push({
        name: 'deposit-reconciliation',
        params: {
          id: item.depo_id,
        },
      })
    },

    openOperationDialog() {
      this.dialog = true
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadDeposits(this.$route.query),
      ]).then(() => {

      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })

    },
    editItem(item) {

      this.$router.push({ name: 'deposit-edit', params: { id: item.depo_id, query: this.$route.query } })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({ path: this.$route.path, query })
    },

    loadQuery() {
      this.product_id = this.$route.query.product_id ?? ''
      this.customer_id = parseInt(this.$route.query.cust_id) ?? ''
      this.contract_status = this.$route.query.contract_status ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    downloadCSV() {

      // // eslint-disable-next-line no-useless-concat
      // let csv = '\ufeff' + 'No.,契約ID,契約日,取引先ID,契約名,ペレットID,重量(kg),契約単価,備考\n'
      // let no = 0
      // this.contractList.forEach(el => {
      //   no += 1
      //   const contract_id = "CP-" + el.contract_id +"-" + el.detail_id
      //   const contract_date = el.contract_date ?? ""
      //   const customer_id = el.customer_id ?? ""
      //   const contract_name = el.contract_name ?? ""
      //   const product_id = el.product_id ?? ""
      //   const contract_weight = el.contract_weight ? el.contract_weight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
      //   const contract_price = el.contract_price ? el.contract_price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
      //   const note  = el.contract_note ?? ""
      //   const line = `"${no}","${contract_id}","${contract_date}","${customer_id}","${contract_name}","${product_id}","${contract_weight}","${contract_price}","${note}"\n`
      //   csv += line
      // })
      // const blob = new Blob([csv], { type: 'text/csv' })
      // const link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      // const newstr = nowstr.replace(/(-|:|T)/g, '')
      // link.download = `ペレット契約一覧-${newstr}.csv`
      // link.click()
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
tr {
  height: 50px;
  font-size: 14px;
}
td {
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
  padding: 0px 16px;
}
</style>
